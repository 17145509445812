<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true">
      <img src="../../assets/loader.gif" />
    </vue-element-loading>
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap>
      <v-flex py-4 xs12 md12>
        <v-card style="min-width:70vw" outlined tile>
          <v-layout pa-4 wrap justify-center>
            <v-flex xs12 sm12 md12 text-center>
              <span style="font-size:18px;font-weight:400">General FAQ</span>
            </v-flex>
          </v-layout>
          <v-layout px-4 wrap justify-center v-for="(item,i) in faq" :key="i">
            <v-flex xs12 sm12 md12>
              <v-textarea
                ref="question"
                v-model="item.question"
                label="Question"
                color="#26af82"
                rows="2"
                required
              ></v-textarea>
              <v-textarea
                ref="answer"
                v-model="item.answer"
                label="Answer"
                color="#26af82"
                rows="4"
                required
              ></v-textarea>
            </v-flex>
            <v-flex px-4 v-if=" i == lastIndex" text-left>
              <v-btn
                @click="addFaq"
                small
                dark
                :ripple="false"
                class="text-capitalize"
                color="#26af82"
                width="250"
              >Add More FAQ</v-btn>
            </v-flex>
            <v-flex px-4 text-right>
              <v-btn
                @click="faq.splice(item, 1)"
                small
                dark
                :ripple="false"
                class="text-capitalize"
                color="red"
                width="250"
              >Delete</v-btn>
            </v-flex>
          </v-layout>
          <v-layout pa-4 wrap justify-center>
            <v-flex v-if="faq.length>0" xs12 sm12 md12 text-center>
              <v-btn
                @click="submitfaq"
                small
                dark
                :ripple="false"
                class="text-capitalize"
                color="#26af82"
                width="250"
              >
                <span v-if="faq.length > 0">Update FAQ</span>
                <span v-else>Add FAQ</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      msg: null,
      timeout: 10000,
      faq: [],
      lastIndex: 0
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    addFaq: function() {
      this.faq.push({
        question: null,
        answer: null
      });
      this.lastIndex = this.lastIndex + 1;
    },
    loadData: function() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/faq/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.status) {
            this.faq = [];
            var data = response.data.data;
            for (var i = 0; i < data.length; i++) {
              this.faq.push({
                question: data[i].question,
                answer: data[i].answer,
                _id: data[i]._id
              });
            }
            this.lastIndex = this.faq.length - 1;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.msg = err;
          this.$router.push("/ServerError");
        });
    },
    submitfaq() {
      this.appLoading = true;
      var data = {};
      data["data"] = this.faq;
      axios({
        method: "POST",
        url: "/faq/addall",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.appLoading = false;
            this.loadData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.msg = err;
          this.$router.push("/ServerError");
        });
    }
  }
};
</script>
<style>
.v-input {
  padding-left: 10px;
  padding-right: 10px;
}
</style>